import React, { useEffect } from "react";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

const CongratulationsVideo = () => {
  useEffect(() => {
    Fancybox.show([{ src: "https://www.youtube.com/watch?v=2aR_5-JI_oA?autoplay=1", type: "iframe" }]);
  }, []);
  return (
    <></>
  );
};

export default CongratulationsVideo;